import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { getFirestore, collection, getDocs, doc, setDoc } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from "../firebase";
import "./SendMessage.css";

const SendMessage = () => {
    const [textMessage, setTextMessage] = useState('');
    const [mediaType, setMediaType] = useState('video');
    const [mediaFile, setMediaFile] = useState(null);
    const [mediaPreview, setMediaPreview] = useState(null);
    const [users, setUsers] = useState([]);
    const [userIds, setUserIds] = useState([]);
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [buttons, setButtons] = useState([{ text: '', url: '' }]);
    const [sendWithMedia, setSendWithMedia] = useState(true);

    const mediaRef = useRef(null);
    const fileInputRef = useRef(null);

    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const BOT_TOKEN = "8022134984:AAH3uGzx0OjKNnFFQA6pN2UPwuBipLazEKA";

    const formatDate = (date) => {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const year = d.getFullYear();
        const hours = String(d.getHours()).padStart(2, '0');
        const minutes = String(d.getMinutes()).padStart(2, '0');
        return `${day}.${month}.${year} ${hours}:${minutes}`;
    };

    const updateUserStatus = async (userId, success, additionalData = {}) => {
        try {
            await setDoc(doc(db, 'users', String(userId)), {
                isActive: success,
                lastStatusUpdate: formatDate(new Date()),
                ...additionalData
            }, { merge: true });
            console.log(`Updated status for user ${userId}: ${success ? 'active' : 'inactive'}`);
        } catch (error) {
            console.error('Error updating user status:', error);
        }
    };

    const fetchUserIds = useCallback(async () => {
        try {
            const usersCollection = collection(db, 'users');
            const userSnapshot = await getDocs(usersCollection);
            const fetchedUsers = userSnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    userId: data.userId,
                    firstName: data.firstName,
                    username: data.username,
                    phoneNumber: data.phoneNumber,
                    isActive: data.isActive,
                    lastStatusUpdate: data.lastStatusUpdate,
                    subscriptionDate: data.subscriptionDate
                };
            });

            setUsers(fetchedUsers);
            const activeIds = fetchedUsers
                .filter(user => user.isActive)
                .map(user => user.userId);
            setUserIds(activeIds);
        } catch (error) {
            console.error('Error fetching users:', error);
            setStatus('Failed to fetch users. Please try again.');
        }
    }, [db]);

    useEffect(() => {
        fetchUserIds();
    }, [fetchUserIds]);

    const handleTextMessageChange = (e) => setTextMessage(e.target.value);

    const handleMediaTypeChange = (e) => {
        setMediaType(e.target.value);
        setMediaFile(null);
        setMediaPreview(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleMediaChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setMediaFile(file);
            const newMediaUrl = URL.createObjectURL(file);
            setMediaPreview(newMediaUrl);
        }
    };

    const handleSendWithMediaChange = (e) => {
        setSendWithMedia(e.target.checked);
    };

    const handleButtonChange = (index, field, value) => {
        const newButtons = [...buttons];
        newButtons[index] = { ...newButtons[index], [field]: value };
        setButtons(newButtons);
    };

    const addButton = () => {
        setButtons([...buttons, { text: '', url: '' }]);
    };

    const removeButton = (index) => {
        setButtons(buttons.filter((_, i) => i !== index));
    };

    useEffect(() => {
        return () => {
            if (mediaPreview) {
                URL.revokeObjectURL(mediaPreview);
            }
        };
    }, [mediaPreview]);

    const createInlineKeyboard = () => {
        const validButtons = buttons.filter(btn => btn.text && btn.url);
        if (validButtons.length === 0) return null;

        return {
            inline_keyboard: [
                validButtons.map(btn => ({
                    text: btn.text,
                    url: btn.url
                }))
            ]
        };
    };

    const sendMessageAndMediaToTelegram = async () => {
        if (!mediaFile && !textMessage) {
            setStatus('Пожалуйста, выберите медиафайл или введите текст сообщения.');
            return;
        }

        setLoading(true);
        setStatus('Отправка сообщения всем пользователям...');
        let successCount = 0;
        let failedUsers = [];

        const inlineKeyboard = createInlineKeyboard();

        try {
            for (const userId of userIds) {
                try {
                    try {
                        await axios.post(`https://api.telegram.org/bot${BOT_TOKEN}/sendChatAction`, {
                            chat_id: userId,
                            action: 'typing'
                        });
                    } catch (error) {
                        if (error.response?.data?.description?.includes('bot was blocked') ||
                            error.response?.data?.description?.includes('user is deactivated') ||
                            error.response?.data?.description?.includes('chat not found')) {
                            const user = users.find(u => u.userId === userId);
                            failedUsers.push({
                                userId,
                                phoneNumber: user?.phoneNumber || 'No phone',
                                firstName: user?.firstName || 'Unknown',
                                error: 'Бот был удален/заблокирован.',
                                failedAt: formatDate(new Date())
                            });

                            await updateUserStatus(userId, false, {
                                lastFailedAttempt: formatDate(new Date())
                            });
                            continue;
                        }
                    }

                    let messageSent = false;

                    if (mediaFile) {
                        if (sendWithMedia && textMessage) {
                            const formData = new FormData();
                            formData.append('chat_id', userId);
                            formData.append('caption', textMessage);

                            if (mediaType === 'video') {
                                formData.append('video', mediaFile);
                                if (inlineKeyboard) {
                                    formData.append('reply_markup', JSON.stringify(inlineKeyboard));
                                }
                                const response = await axios.post(
                                    `https://api.telegram.org/bot${BOT_TOKEN}/sendVideo`,
                                    formData,
                                    { headers: { 'Content-Type': 'multipart/form-data' } }
                                );
                                messageSent = response.data && response.data.ok;
                            } else {
                                formData.append('photo', mediaFile);
                                if (inlineKeyboard) {
                                    formData.append('reply_markup', JSON.stringify(inlineKeyboard));
                                }
                                const response = await axios.post(
                                    `https://api.telegram.org/bot${BOT_TOKEN}/sendPhoto`,
                                    formData,
                                    { headers: { 'Content-Type': 'multipart/form-data' } }
                                );
                                messageSent = response.data && response.data.ok;
                            }
                        } else {
                            const mediaFormData = new FormData();
                            mediaFormData.append('chat_id', userId);

                            if (mediaType === 'video') {
                                mediaFormData.append('video', mediaFile);
                                if (inlineKeyboard) {
                                    mediaFormData.append('reply_markup', JSON.stringify(inlineKeyboard));
                                }
                                const videoResponse = await axios.post(
                                    `https://api.telegram.org/bot${BOT_TOKEN}/sendVideo`,
                                    mediaFormData,
                                    { headers: { 'Content-Type': 'multipart/form-data' } }
                                );
                                messageSent = videoResponse.data && videoResponse.data.ok;
                            } else {
                                mediaFormData.append('photo', mediaFile);
                                if (inlineKeyboard) {
                                    mediaFormData.append('reply_markup', JSON.stringify(inlineKeyboard));
                                }
                                const photoResponse = await axios.post(
                                    `https://api.telegram.org/bot${BOT_TOKEN}/sendPhoto`,
                                    mediaFormData,
                                    { headers: { 'Content-Type': 'multipart/form-data' } }
                                );
                                messageSent = photoResponse.data && photoResponse.data.ok;
                            }

                            if (!sendWithMedia && textMessage) {
                                const messageData = {
                                    chat_id: userId,
                                    text: textMessage,
                                    reply_markup: inlineKeyboard
                                };

                                const textResponse = await axios.post(
                                    `https://api.telegram.org/bot${BOT_TOKEN}/sendMessage`,
                                    messageData
                                );
                                messageSent = messageSent && textResponse.data && textResponse.data.ok;
                            }
                        }
                    } else if (textMessage) {
                        const messageData = {
                            chat_id: userId,
                            text: textMessage,
                            reply_markup: inlineKeyboard
                        };

                        const response = await axios.post(
                            `https://api.telegram.org/bot${BOT_TOKEN}/sendMessage`,
                            messageData
                        );
                        messageSent = response.data && response.data.ok;
                    }

                    if (messageSent) {
                        successCount++;
                        await updateUserStatus(userId, true, {
                            lastSuccessfulMessage: formatDate(new Date())
                        });
                    }

                } catch (error) {
                    console.error(`Error sending to user ${userId}:`, error);
                    const user = users.find(u => u.userId === userId);
                    failedUsers.push({
                        userId,
                        phoneNumber: user?.phoneNumber || 'No phone',
                        firstName: user?.firstName || 'Unknown',
                        error: error.response?.data?.description || error.message,
                        failedAt: formatDate(new Date())
                    });
                }
            }

            let statusMessage = `Успешно отправлено ${successCount} пользователям.\n`;
            if (failedUsers.length > 0) {
                statusMessage += '\nНе удалось отправить следующим пользователям:\n';
                failedUsers.forEach(user => {
                    statusMessage += `\nID: ${user.userId}`;
                    statusMessage += `\nИмя: ${user.firstName}`;
                    statusMessage += `\nТелефон: ${user.phoneNumber}`;
                    statusMessage += `\nВремя: ${user.failedAt}`;
                    if (user.error) {
                        statusMessage += `\nОшибка: ${user.error}`;
                    }
                    statusMessage += '\n';
                });
            }

            setStatus(statusMessage);

            // Сброс формы
            setTextMessage('');
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            setMediaFile(null);
            setMediaPreview(null);
            setButtons([{ text: '', url: '' }]);

            await fetchUserIds();

        } catch (error) {
            console.error('Error in send operation:', error);
            setStatus('Произошла ошибка при отправке сообщений. Пожалуйста, попробуйте снова.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="send-message">
            <h2>Отправка сообщений</h2>

            <div className="users-info">
                <h3>Информация о пользователях</h3>
                <p>Всего пользователей: {users.length}</p>
                <p>Активных пользователей: {users.filter(u => u.isActive).length}</p>
                <p>Неактивных пользователей: {users.filter(u => !u.isActive).length}</p>
            </div>

            <div className="message-form">
                <div className="text-input-container">
                    <textarea
                        value={textMessage}
                        onChange={handleTextMessageChange}
                        placeholder="Введите сообщение"
                        className="message-textarea"
                        disabled={loading}
                    />
                </div>

                <div className="media-type-selector">
                    <label>
                        <input
                            type="radio"
                            value="video"
                            checked={mediaType === 'video'}
                            onChange={handleMediaTypeChange}
                            disabled={loading}
                        />
                        Видео
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="photo"
                            checked={mediaType === 'photo'}
                            onChange={handleMediaTypeChange}
                            disabled={loading}
                        />
                        Фото
                    </label>
                </div>

                <div className="file-input-container">
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept={mediaType === 'video' ? "video/*" : "image/*"}
                        onChange={handleMediaChange}
                        disabled={loading}
                        className="file-input"
                    />
                    {mediaFile && (
                        <div className="media-options">
                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={sendWithMedia}
                                    onChange={handleSendWithMediaChange}
                                    disabled={loading}
                                />
                                Отправить текст как подпись к {mediaType === 'video' ? 'видео' : 'фото'}
                            </label>
                        </div>
                    )}
                </div>

                <div className="buttons-section">
                    <h3>Кнопки с URL</h3>
                    {buttons.map((button, index) => (
                        <div key={index} className="button-inputs">
                            <input
                                type="text"
                                value={button.text}
                                onChange={(e) => handleButtonChange(index, 'text', e.target.value)}
                                placeholder="Текст кнопки"
                                className="button-input"
                                disabled={loading}
                            />
                            <input
                                type="url"
                                value={button.url}
                                onChange={(e) => handleButtonChange(index, 'url', e.target.value)}
                                placeholder="URL кнопки (https://...)"
                                className="button-input"
                                disabled={loading}
                            />
                            <button
                                onClick={() => removeButton(index)}
                                className="remove-button"
                                disabled={loading || buttons.length === 1}
                            >
                                Удалить
                            </button>
                        </div>
                    ))}
                    <button
                        onClick={addButton}
                        className="add-button"
                        disabled={loading}
                    >
                        Добавить кнопку
                    </button>
                </div>

                {mediaPreview && (
                    <div className="media-preview">
                        {mediaType === 'video' ? (
                            <video
                                ref={mediaRef}
                                controls
                                src={mediaPreview}
                                width="300"
                            />
                        ) : (
                            <img
                                src={mediaPreview}
                                alt="Preview"
                                style={{ maxWidth: '300px' }}
                            />
                        )}
                    </div>
                )}

                <button
                    onClick={sendMessageAndMediaToTelegram}
                    disabled={loading || (!mediaFile && !textMessage)}
                    className={`send-button ${loading ? 'loading' : ''}`}
                >
                    {loading ? 'Отправка...' : 'Отправить'}
                </button>

                {status && (
                    <div className={`status-message ${status.includes('Failed') ? 'error' : 'success'}`}>
                        <pre>{status}</pre>
                    </div>
                )}
            </div>

            {loading && (
                <div className="loading-overlay">
                    <div className="loading-spinner"></div>
                </div>
            )}
        </div>
    );
};

export default SendMessage;