import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from './firebase';
import Login from "./pages/Login";
import Dashboard from "./components/Dashboard";
import SendMessage from "./components/SendMessage";
import UpdateBot from "./components/UpdateBot";

const App = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <Routes>
                <Route path="/" element={
                    user ? <Navigate to="/dashboard/sendmessage" replace /> : <Navigate to="/login" replace />
                } />
                <Route path="/login" element={
                    user ? <Navigate to="/dashboard/sendmessage" replace /> : <Login />
                } />
                {user ? (
                    <Route path="/dashboard" element={<Dashboard />}>
                        <Route index element={<Navigate to="/dashboard/sendmessage" replace />} />
                        <Route path="sendmessage" element={<SendMessage />} />
                        <Route path="updatebot" element={<UpdateBot />} />
                        <Route path="*" element={<Navigate to="/dashboard/sendmessage" replace />} />
                    </Route>
                ) : (
                    <Route path="/dashboard/*" element={<Navigate to="/login" replace />} />
                )}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Router>
    );
};

export default App;