// components/ConfirmDialog.jsx
import React from 'react';
import './ConfirmDialog.css';

export const ConfirmDialog = ({
                                  isOpen,
                                  onClose,
                                  onConfirm,
                                  title,
                                  message,
                                  cancelText = "Cancel",
                                  confirmText = "Confirm"
                              }) => {
    if (!isOpen) return null;

    return (
        <div className="dialog-overlay" onClick={onClose}>
            <div className="dialog-content" onClick={e => e.stopPropagation()}>
                <div className="dialog-header">
                    <h3 className="dialog-title">{title}</h3>
                </div>
                <div className="dialog-body">
                    {message}
                </div>
                <div className="dialog-footer">
                    <button
                        className="dialog-button cancel"
                        onClick={onClose}
                    >
                        {cancelText}
                    </button>
                    <button
                        className="dialog-button confirm"
                        onClick={onConfirm}
                    >
                        {confirmText}
                    </button>
                </div>
            </div>
        </div>
    );
};

// components/SuccessAlert.jsx
export const SuccessAlert = ({ message, isVisible }) => {
    if (!isVisible) return null;

    return (
        <div className="success-alert">
            <div className="success-icon">✓</div>
            <div className="success-message">{message}</div>
        </div>
    );
};