import React, { useState, useEffect, useCallback, useRef, useReducer } from 'react';
import axios from 'axios';
import { ConfirmDialog, SuccessAlert } from './ConfirmDialog';
import './UpdateBot.css';

const initialState = {
    contents: {},
    loading: false,
    error: null,
    initialized: false
};

function contentReducer(state, action) {
    switch (action.type) {
        case 'INIT_SUCCESS':
            return { ...state, initialized: true };
        case 'SET_CONTENT':
            return {
                ...state,
                contents: {
                    ...state.contents,
                    [action.contentType]: action.payload
                }
            };
        case 'UPDATE_CONTENT_TEXT':
            return {
                ...state,
                contents: {
                    ...state.contents,
                    [action.contentType]: {
                        ...state.contents[action.contentType],
                        text: action.payload
                    }
                }
            };
        case 'UPDATE_CONTENT_URL':
            return {
                ...state,
                contents: {
                    ...state.contents,
                    [action.contentType]: {
                        ...state.contents[action.contentType],
                        url: action.payload
                    }
                }
            };
        case 'SET_LOADING':
            return { ...state, loading: action.payload };
        case 'SET_ERROR':
            return { ...state, error: action.payload };
        default:
            return state;
    }
}

// const API_BASE_URL = 'http://localhost:5010/';
const API_BASE_URL =  'https://chat.bratanchatbot.xyz/';


const contentTypes = [
    'preview_welcome', 'welcome', 'contact', 'sports_book',
    'casino', 'sport_casino', 'live_casino', 'how_use_bonus'
];

const sectionsWithUrl = [
    'sports_book', 'casino', 'sport_casino', 'live_casino', 'how_use_bonus'
];

const validateFileSize = (file) => {
    const maxSize = 20 * 1024 * 1024; // 20MB
    if (file.size > maxSize) {
        return {
            valid: false,
            error: 'File size exceeds 20MB limit. Please choose a smaller file.'
        };
    }
    if (!file.type.startsWith('video/')) {
        return {
            valid: false,
            error: 'Only video files are allowed.'
        };
    }
    return { valid: true };
};

const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const UpdateBot = () => {
    const [state, dispatch] = useReducer(contentReducer, initialState);
    const [selectedContentType, setSelectedContentType] = useState('preview_welcome');
    const [videoFile, setVideoFile] = useState(null);
    const [videoPreview, setVideoPreview] = useState(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const videoRef = useRef(null);
    const initialized = useRef(false);

    // Reset video state when changing sections
    useEffect(() => {
        if (videoPreview) {
            URL.revokeObjectURL(videoPreview);
            setVideoPreview(null);
        }
        setVideoFile(null);

        const currentContent = state.contents[selectedContentType];
        if (currentContent?.videoUrl) {
            setVideoPreview(currentContent.videoUrl);
        }
    }, [selectedContentType]);

    useEffect(() => {
        const init = async () => {
            if (!initialized.current) {
                try {
                    await axios.get(`${API_BASE_URL}api/get-text/preview_welcome`);
                    dispatch({ type: 'INIT_SUCCESS' });
                    initialized.current = true;
                } catch (error) {
                    dispatch({ type: 'SET_ERROR', payload: 'Unable to connect to server' });
                }
            }
        };
        init();
    }, []);

    const fetchContent = useCallback(async (contentType) => {
        if (!state.initialized || state.contents[contentType]) return;

        dispatch({ type: 'SET_LOADING', payload: true });

        try {
            const [textResponse, videoResponse, urlResponse] = await Promise.all([
                axios.get(`${API_BASE_URL}api/get-text/${contentType}`),
                axios.get(`${API_BASE_URL}api/get-video/${contentType}`, {
                    responseType: 'blob'
                }).catch(() => ({ data: null })),
                sectionsWithUrl.includes(contentType)
                    ? axios.get(`${API_BASE_URL}api/get-url/${contentType}`)
                        .catch(() => ({ data: { url: '' } }))
                    : Promise.resolve({ data: { url: '' } })
            ]);

            let videoUrl = null;
            if (videoResponse.data && videoResponse.data.size > 0) {
                videoUrl = URL.createObjectURL(videoResponse.data);
                if (contentType === selectedContentType) {
                    setVideoPreview(videoUrl);
                }
            }

            dispatch({
                type: 'SET_CONTENT',
                contentType,
                payload: {
                    text: textResponse.data.text || '',
                    videoUrl,
                    url: urlResponse.data.url || ''
                }
            });
        } catch (error) {
            console.error(`Error fetching content for ${contentType}:`, error);
            dispatch({ type: 'SET_ERROR', payload: `Failed to fetch content for ${contentType}` });
        } finally {
            dispatch({ type: 'SET_LOADING', payload: false });
        }
    }, [state.initialized, selectedContentType]);

    useEffect(() => {
        if (state.initialized && !state.contents[selectedContentType]) {
            fetchContent(selectedContentType);
        }
    }, [selectedContentType, state.initialized, fetchContent]);

    useEffect(() => {
        return () => {
            Object.values(state.contents).forEach(content => {
                if (content?.videoUrl) {
                    URL.revokeObjectURL(content.videoUrl);
                }
            });
            if (videoPreview) {
                URL.revokeObjectURL(videoPreview);
            }
        };
    }, []);

    const handleVideoChange = useCallback((e) => {
        const file = e.target.files[0];
        if (file) {
            const validation = validateFileSize(file);
            if (!validation.valid) {
                dispatch({
                    type: 'SET_ERROR',
                    payload: validation.error
                });
                e.target.value = '';
                return;
            }

            if (videoPreview) {
                URL.revokeObjectURL(videoPreview);
            }
            setVideoFile(file);
            setVideoPreview(URL.createObjectURL(file));
        }
    }, [videoPreview]);

    const handleVideoUpload = async () => {
        if (!videoFile || !state.initialized) {
            dispatch({ type: 'SET_ERROR', payload: 'Please select a video file' });
            return;
        }

        dispatch({ type: 'SET_LOADING', payload: true });

        const formData = new FormData();
        formData.append('video', videoFile);

        try {
            await axios.post(
                `${API_BASE_URL}api/upload-video/${selectedContentType}`,
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }
            );

            // Очищаем текущий контент для перезагрузки
            dispatch({
                type: 'SET_CONTENT',
                contentType: selectedContentType,
                payload: undefined
            });

            // Очищаем видео
            if (videoPreview) {
                URL.revokeObjectURL(videoPreview);
            }

            setVideoFile(null);
            setVideoPreview(null);

            // Перезагружаем контент
            await fetchContent(selectedContentType);

            setShowSuccessAlert(true);
            setTimeout(() => setShowSuccessAlert(false), 5000);
        } catch (error) {
            console.error('Error uploading video:', error);
            dispatch({ type: 'SET_ERROR', payload: 'Failed to upload video' });
        } finally {
            dispatch({ type: 'SET_LOADING', payload: false });
        }
    };

    const handleConfirmUpdate = async () => {
        setShowConfirmDialog(false);
        dispatch({ type: 'SET_LOADING', payload: true });

        try {
            const content = state.contents[selectedContentType];
            const updatePromises = [
                axios.post(`${API_BASE_URL}api/update-text/${selectedContentType}`, {
                    text: content.text,
                })
            ];

            if (sectionsWithUrl.includes(selectedContentType)) {
                updatePromises.push(
                    axios.post(`${API_BASE_URL}api/update-url/${selectedContentType}`, {
                        url: content.url,
                    })
                );
            }

            await Promise.all(updatePromises);
            dispatch({ type: 'SET_ERROR', payload: null });
            setShowSuccessAlert(true);
            setTimeout(() => setShowSuccessAlert(false), 5000);
        } catch (error) {
            console.error('Error updating content:', error);
            dispatch({ type: 'SET_ERROR', payload: 'Failed to update content' });
        } finally {
            dispatch({ type: 'SET_LOADING', payload: false });
        }
    };

    const currentContent = state.contents[selectedContentType] || { text: '', url: '' };

    return (
        <div className="update-content">
            {state.error && (
                <div className="error-message">
                    {state.error}
                </div>
            )}

            <h2>Update Bot Content</h2>

            <select
                value={selectedContentType}
                onChange={(e) => setSelectedContentType(e.target.value)}
                className="content-type-select"
                disabled={state.loading}
            >
                {contentTypes.map(type => (
                    <option key={type} value={type}>{type}</option>
                ))}
            </select>

            <div className="content-section">
                <h3>Text Content</h3>
                <textarea
                    value={currentContent.text}
                    onChange={(e) => dispatch({
                        type: 'UPDATE_CONTENT_TEXT',
                        contentType: selectedContentType,
                        payload: e.target.value
                    })}
                    placeholder="Update the content text"
                    className="content-textarea"
                    disabled={state.loading}
                />
            </div>

            {sectionsWithUrl.includes(selectedContentType) && (
                <div className="url-section">
                    <h3>URL</h3>
                    <div className="url-input-container">
                        <input
                            type="text"
                            value={currentContent.url}
                            onChange={(e) => dispatch({
                                type: 'UPDATE_CONTENT_URL',
                                contentType: selectedContentType,
                                payload: e.target.value
                            })}
                            placeholder="Enter URL for this section"
                            className="url-input"
                            disabled={state.loading}
                        />
                    </div>
                </div>
            )}

            <div className="video-section">
                <h3>Video Content - {selectedContentType}</h3>
                <div className="file-input-container">
                    <p className="file-requirements">
                        Maximum file size: 20MB. Supported formats: MP4, WebM, MOV
                    </p>
                    <input
                        type="file"
                        accept="video/*"
                        onChange={handleVideoChange}
                        disabled={state.loading}
                    />
                    {videoFile && (
                        <p className="file-info">
                            Selected file: {videoFile.name} ({formatFileSize(videoFile.size)})
                        </p>
                    )}
                </div>
                {videoPreview && (
                    <div className="video-preview">
                        <video
                            ref={videoRef}
                            key={`video-${selectedContentType}`}
                            controls
                            src={videoPreview}
                            width="300"
                        />
                    </div>
                )}
                {videoFile && (
                    <button
                        onClick={handleVideoUpload}
                        className="upload-button"
                        disabled={state.loading}
                    >
                        {state.loading ? 'Uploading...' : 'Upload Video'}
                    </button>
                )}
            </div>

            <div className="button-container">
                <button
                    onClick={() => setShowConfirmDialog(true)}
                    className="update-button"
                    disabled={state.loading}
                >
                    {state.loading ? 'Updating...' : 'Update Content'}
                </button>
            </div>

            <ConfirmDialog
                isOpen={showConfirmDialog}
                onClose={() => setShowConfirmDialog(false)}
                onConfirm={handleConfirmUpdate}
                title="Confirm Content Update"
                message={
                    <>
                        Are you sure you want to update this content? After updating, please verify the changes in the Telegram Bot.
                        <br /><br />
                        Section: <strong>{selectedContentType}</strong>
                    </>
                }
                confirmText="Update Content"
            />

            <SuccessAlert
                isVisible={showSuccessAlert}
                message="Content has been successfully updated. Please check the Telegram Bot to verify the changes."
            />

            {state.loading && (
                <div className="loading-overlay">
                    <div className="loading-spinner"></div>
                </div>
            )}
        </div>
    );
};

export default UpdateBot;