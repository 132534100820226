import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Navigate, useNavigate } from 'react-router-dom';
import './Login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const auth = getAuth();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            setMessage('Login successful');
            navigate('/dashboard');
        } catch (error) {
            setMessage('Invalid credentials');
            console.error('Login error:', error);
        }
    };

    return (
        <div className="login-container">
            <div className="login-card">
                <h2 className="login-title">Login to Dashboard</h2>
                <form onSubmit={handleSubmit} className="login-form">
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="form-input"
                            placeholder="Enter your email"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password:</label>
                        <input
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="form-input"
                            placeholder="Enter your password"
                        />
                    </div>
                    <button type="submit" className="login-button">
                        Login
                    </button>
                </form>
                {message && (
                    <div className={`message ${message.startsWith('Invalid') ? 'error' : 'success'}`}>
                        {message}
                    </div>
                )}
                <div className="additional-options">
                    {/* Commented buttons preserved for future use */}
                    {/*<button onClick={() => navigate('/register')} className="secondary-button">*/}
                    {/*    Register*/}
                    {/*</button>*/}
                    {/*<button onClick={() => navigate('/password-reset')} className="secondary-button">*/}
                    {/*    Forgot Password*/}
                    {/*</button>*/}
                </div>
            </div>
        </div>
    );
};

export default Login;